<script lang="ts" setup>
import type { PlayersActivityGame } from "@/types";

const { t } = useT();
const { open } = useNlcModals();
const { handleOpenGame } = useOpenGame(open);
const loginGuard = useLoginGuard();
const { activeGameMode } = useSwitchMode();

const sliderActiveSlide = ref(0);
const [isScrolling, setScrolling] = useToggle(false);

const { playersActivity, fetchedPlayersActivity } = useLatestWinners({ enableThrottling: true, isScrolling });
const documentVisibleState = useDocumentVisibility();

let scrollEndTimeout: ReturnType<typeof setTimeout> | null = null;

const disableDrag = (e: MouseEvent) => {
	e.preventDefault();
};

const openGame = (game: PlayersActivityGame) => {
	loginGuard({
		success: () => {
			if (!game?.slug) {
				return;
			}
			if (game?.gameExclusiveData?.isExclusive && activeGameMode.value === "coins") {
				open("LazyOModalGameExclusive", { gameId: game.id });
				return;
			}
			if (game.isOnlyForApp) {
				dispatchQuasarEvent("quasar:openLockedGame", { detail: game });
			} else {
				handleOpenGame(game?.slug);
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const onScroll = () => {
	setScrolling(true);

	if (scrollEndTimeout) {
		clearTimeout(scrollEndTimeout);
	}

	scrollEndTimeout = setTimeout(() => {
		setScrolling(false);
	}, 2000);
};
</script>
<template>
	<div class="list-winners-wrapper" data-tid="last-winners">
		<MHomeSectionHeader :title="t('Latest Winners')" icon="24/tournaments" />
		<ClientOnly>
			<AHorizontalScroll shadowEffect @scroll="onScroll">
				<div v-if="documentVisibleState === 'hidden'" class="box-winners">
					<TransitionGroup :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
						<MLatestWinnersCard
							v-for="(element, index) in fetchedPlayersActivity"
							:key="element.id"
							class="card-item"
							:data-tid="`last-winners-card-${index}`"
							:item="element"
							@open-game="openGame"
							@mousedown="disableDrag"
							@dragstart="disableDrag"
						/>
					</TransitionGroup>
				</div>
				<div v-if="documentVisibleState === 'visible'" class="box-winners">
					<TransitionGroup :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
						<template v-if="!playersActivity?.length">
							<AAnimationSkeleton v-for="i in 6" :key="i">
								<ASkeleton width="205px" height="97px" />
							</AAnimationSkeleton>
						</template>
						<template v-else>
							<MLatestWinnersCard
								v-for="(element, index) in playersActivity"
								:key="element.id"
								class="card-item"
								:data-tid="`last-winners-card-${index}`"
								:item="element"
								@open-game="openGame"
								@mousedown="disableDrag"
								@dragstart="disableDrag"
							/>
						</template>
					</TransitionGroup>
				</div>
			</AHorizontalScroll>
		</ClientOnly>
	</div>
</template>

<style scoped lang="scss">
div.list-winners-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	overflow: hidden;

	@include media-breakpoint-up(lg) {
		gap: 16px;
	}
}

.box-winners {
	display: flex;
	gap: 12px;

	@include media-breakpoint-up(md) {
		gap: 16px;
	}
}

.flip-list-move {
	transition: transform 0.8s ease;
}

.list-transform-move {
	transition: transform 0.8s;
}
.list-transform-enter-active,
.list-transform-leave-active {
	transition: all 0.8s ease;
}

.list-transform-enter-from,
.list-transform-leave-to {
	transform: translateX(-200px);
	opacity: 0;
}

.list-transform-enter-to {
	transform: translateX(0);
	opacity: 1;
}

.card {
	padding: 0 8px;

	@include media-breakpoint-down(md) {
		padding: 0 6px;
	}
}

.card-item {
	width: 100%;
}

@include media-breakpoint-down(md) {
	.scroll-container {
		width: calc(100% + 32px);
		margin-left: -16px;
		padding-left: 16px;
		padding-right: 16px;
	}
}
</style>
